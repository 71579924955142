import $ from 'jquery';
import 'what-input';
import LazyLoad from "vanilla-lazyload";

require('readmore-js');

// Inicializace jQuery
window.jQuery = $;

// Import Zurb Foundation
// require('foundation-sites');
import './lib/foundation-explicit-pieces';

// Lazyloading images
let lazy = new LazyLoad({
    elements_selector: ".lazy"
});

// Iniciazlizace voundationu
let foundation = $(document).foundation();

$(document).ready(function(){


    $(".scrollto").on("click",function(event){
        event.preventDefault();

        let target = $(this).attr("data-target");
        let bodyRect = document.body.getBoundingClientRect();
        let targetRect = document.getElementById(target).getBoundingClientRect();
        let offset = targetRect.top - bodyRect.top - 80;
        // console.log(offset);
        // foundation.scrollTop( offset );

        $("html, body").stop().animate({scrollTop:offset}, 500, 'swing', function() { 
            // alert("Finished animating");
         });
        
    });

    // Rozklikávání buňek
    let $cell = $(".expandable-list .standard-list-item");


    function expand_item( item ){
        if (item.hasClass("standard-list-item")) {

            let expandators = item.find(".expandator");
            let expandator = "";
            expandators.each(function(){
                expandator = $(this);
            });
            
            // otevři zavřené
            if (item.hasClass("collapsed")) {

                $cell.not( item ).removeClass("expanded");
                $cell.not( item ).addClass("collapsed");
                item.removeClass("collapsed");
                item.addClass("expanded");
                expandator.find(".trigger").html("Zavřít");
                $cell.not(item).find(".trigger").each(function(){
                    $(this).html("Více informací");
                });
            } 

            // zavři otevřené
            else {
                item.removeClass('expanded');
                item.addClass('collapsed');
                expandator.find(".trigger").html("Více informací");
                
            }
        } else {
            console.log("není to standardní item");
        }
    }

    $cell.find(".expandator").click(function(){
        
        let $thisCell = $(this).closest(".standard-list-item");

        expand_item($thisCell);
        return;

        if ($thisCell.hasClass("collapsed")) {
            $cell.not($thisCell).removeClass("expanded");
            $cell.not($thisCell).addClass("collapsed");
            $thisCell.removeClass("collapsed");
            $thisCell.addClass("expanded");
            $(this).html("Zavřít");
            
        } else {
            $thisCell.removeClass('expanded');
            $thisCell.addClass('collapsed');
            $(this).html("Více informací");
            let tmp = $(this);
            $cell.find(".expanator").each(function(){
                if ( $(this) != tmp ) {
                    $(this).html("Více informací");
                }
            });
        }
    });

    // Readmore
    $(".readmore").readmore({
        speed: 75,
        moreLink: "<div class='readmore-js-trigger more mw'><span class='text-center'>Číst více<br><img src='/assets/img/icons/arrow_down_blue.svg'></span></div>",
        lessLink: "<div class='readmore-js-trigger less mw'><span href='#' class='text-center'>Zavřít<br><img src='/assets/img/icons/close_blue.svg'></span></div>",
        embedCSS: false

    });

    // Offcanvas
    $(".offcanvas-menu a").on("click",function(elem){
        elem.preventDefault();
        console.log(foundation);
        let target = $(this).attr("data-target");
        let bodyRect = document.body.getBoundingClientRect();
        let targetRect = document.getElementById(target).getBoundingClientRect();
        let offset = targetRect.top - bodyRect.top - 80;
        // console.log(offset);
        // foundation.scrollTop( offset );

        $("html, body").stop().animate({scrollTop:offset}, 500, 'swing', function() { 
            // alert("Finished animating");
         });

         $("#offCanvas").foundation("close");

    });

    $("#intro-arrow").on("click",function(){

        // let target = $(this).attr("data-target");
        let bodyRect = document.body.getBoundingClientRect();
        let targetRect = document.getElementById("co-umime").getBoundingClientRect();
        let offset = targetRect.top - bodyRect.top - 80;

        $("html, body").stop().animate({scrollTop:offset}, 500, 'swing', function() { 
            // alert("Finished animating");
         });

    });



});// konec document ready


